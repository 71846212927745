import { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UserContext from "./../components/Auth/UserContext";
import api from "./../api/apiHandler";
import categories from "../data/categories";
import EditDeleteIcons from "./../components/EditDeleteIcons";
import AddEditModal from "../components/AddEditModal";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import styles from "./ProductDetails.module.scss";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [adminModal, setAdminModal] = useState(null);

  const params = useParams();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const getProduct = useCallback(async () => {
    const productData = await api.getOneProduct(params.id);
    if (productData) setProduct(productData);
    else return navigate("/not-found");
  }, [params, navigate]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  if (!product) return; //loader ?
  return (
    <>
      <div className={styles.productWrapper}>
        <div className={styles.bloc1}>
          <ImageGallery
            additionalClass={adminModal ? styles.hideIcons : ""}
            showPlayButton={false}
            onErrorImageURL={process.env.REACT_APP_DEFAULT_PRODUCT_PICTURE}
            items={product.images.map((img) => ({
              original: img,
              thumbnail: img,
              thumbnailClass: styles.thumbnailItem,
              originalAlt: `mpf porcelaines - ${
                categories.find((cat) => cat.dbCategory === product.category)
                  .pageTitle
              } - ${product.name} - porcelaine peinte à la main`,
              thumbnailAlt: `mpf porcelaines - ${
                categories.find((cat) => cat.dbCategory === product.category)
                  .pageTitle
              } - ${product.name} - porcelaine peinte à la main`,
            }))}
          />
        </div>
        <div className={styles.bloc2}>
          {user.isAdmin && (
            <EditDeleteIcons
              onEdit={() => setAdminModal({ type: "edit", product })}
              onDelete={() => setAdminModal({ type: "delete", product })}
            />
          )}
          <h1>{product.name}</h1>
          <p>{product.description_main}</p>
          <p>{product.description_technical}</p>
          {/* <div>Dimensions: </div> */}
          {product.height > 0 && <div>Hauteur : {product.height} cm</div>}
          {product.length > 0 && <div>Longueur : {product.length} cm</div>}
          {product.width > 0 && <div>Largeur : {product.width} cm</div>}
          {product.diameter > 0 && <div>Diamètre : {product.diameter} cm</div>}
          {product.status === "AVAILABLE" && product.price > 0 && (
            <p>Prix : {product.price}€</p>
          )}
          {product.status === "UNAVAILABLE" && (
            <p>
              <i>Non disponible</i>
            </p>
          )}
        </div>
      </div>
      {adminModal?.type === "edit" && (
        <AddEditModal
          type={adminModal.type}
          setAdminModal={setAdminModal}
          product={adminModal.product}
          getproducts={getProduct}
        />
      )}
      {adminModal?.type === "delete" && (
        <ConfirmDeleteModal
          setAdminModal={setAdminModal}
          product={adminModal.product}
          getproducts={() => navigate("/galerie/toutes-les-porcelaines")}
        />
      )}
    </>
  );
};

export default ProductDetails;
