import api from "../api/apiHandler";
import Title from "./Title";
import ModalContainer from "./ModalContainer";

const ConfirmDeleteModal = ({ product, setAdminModal, getproducts }) => {
  const handleConfirm = async () => {
    await api.deleteProduct(product._id);
    getproducts();
    setAdminModal(null);
  };

  return (
    <ModalContainer>
      <Title>Confirmer la suppression définitive de "{product.name}" ?</Title>
      <div></div>
      <div className="buttons-wrapper">
        <button className="btn-black" onClick={handleConfirm}>
          Supprimer
        </button>
        <button className="btn-black" onClick={() => setAdminModal(null)}>
          Annuler
        </button>
      </div>
    </ModalContainer>
  );
};

export default ConfirmDeleteModal;
