import UserContext from "./UserContext";
import { useState, useEffect, useCallback, useMemo } from "react";
import api from "../../api/apiHandler";

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = useCallback((userData) => {
    setUser(userData);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await api.getCurrentUser();
        // console.log("response :>> ", response);
      if (response.role) {
        setUser(response);
      }
    };
    fetchUser();
  }, []);

  const userContextValue = useMemo(
    () => ({
      ...user,
      isLoggedIn : user !== null,
      isAdmin : user?.role === "ADMIN",
      login,
      logout,
    }),
    [user, login, logout]
  );

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
