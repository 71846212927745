import styled from "styled-components";

const LoadingSpinner = styled.div`
  border: 6px solid #f3f3f3;
  border-top: 6px solid #C9E3CC;
  border-radius: 50%;
  width: ${props => props.size || "20px"};
  height: ${props => props.size || "20px"};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingSpinner;
