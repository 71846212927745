import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = styled.form`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 2em auto 1em;
  span {
    color: red;
  }
  label {
    margin: 0 24px 8px;
  }
  input,
  textarea {
    margin: 0 24px 16px;
    padding: 8px 12px;
    border: 1px solid #424340;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  }
  button {
    width: 5em;
    height: 2em;
    align-self: center;
    font-size: 1.1em;
    &:hover {
      background-color: #424340;
      color: white;
    }
    > div {
      margin: 0 auto;
    }
  }
`;

const ContactForm = ({ templateId }) => {
  const [isSending, setIsSending] = useState(false);
  const form = useRef();

  const toastSuccess = () => toast.success("Email envoyé !");
  const toastError = () => toast.error("Échec de l'envoi");
  const toastMissingInput = () =>
    toast.warning("Veuillez renseigner tous les champs requis");
  const toastIncorrectEmail = () =>
    toast.warning("Veuillez entrer une adresse mail valide");

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    if (!e.target[0].value || !e.target[1].value || !e.target[3].value) {
      setIsSending(false);
      return toastMissingInput();
    }

    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const emailIsCorrect = emailFormat.test(e.target[1].value);

    if (!emailIsCorrect) {
      setIsSending(false);
      return toastIncorrectEmail();
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        templateId,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          toastSuccess();
        },
        (error) => {
          console.log(error.text);
          toastError();
        }
      )
      .then(() => setIsSending(false));
  };

  return (
    <>
      <ToastContainer position="top-center" theme="colored" />
      <Form ref={form} onSubmit={sendEmail}>
        <label>
          Nom <span>*</span>
        </label>
        <input type="text" name="user_name" required />
        <label>
          Email <span>*</span>
        </label>
        <input type="email" name="user_email" required />
        <label>Téléphone</label>
        <input type="tel" name="user_phone" />
        <label>
          Message <span>*</span>
        </label>
        <textarea rows="5" name="user_message" required />
        <button className="btn-black" type="submit">
          {isSending ? <LoadingSpinner size="12px" /> : "Envoyer"}
        </button>
      </Form>
    </>
  );
};

export default ContactForm;
