import styled from "styled-components";

const CardWrapper = styled.div`
  /* height: 100%; */
`;
const CategoryImage = styled.img`
  max-width: 100%;
`;

const CategoryCard = ({ category }) => {
  const isMobile = window.innerWidth <= 480;//useWindowSize
  return (
    <CardWrapper>
      {/* <h3>{category.pageTitle}</h3> */}
      <CategoryImage
        src={
          category.dbCategory === "ALL" && isMobile
            ? category.imgMobile
            : category.img
        }
        alt={category.pageTitle}
      ></CategoryImage>
    </CardWrapper>
  );
};

export default CategoryCard;
