import { useContext } from "react";
import UserContext from "./Auth/UserContext";
import { Link, useLocation } from "react-router-dom";
import api from "./../api/apiHandler";
import styles from "./Navbar.module.scss";
import categories from "../data/categories";
import capitalizeFirstLetter from "./../utils/capitalize";

const navItems = ["accueil", "galerie", "contact"];

const Navbar = () => {
  const user = useContext(UserContext);
  const currentPage = useLocation().pathname.replace("/", "");

  const getItemClass = (item) => {
    if (item === currentPage || (item === "accueil" && currentPage === ""))
      return styles.currentItem;
    return styles.item;
  };

  const logout = async () => {
    try {
      const response = await api.logout();
      console.log(response);
      user.logout();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ul className={styles.navContainer}>
      {navItems.map((item) => (
        <li key={item} name={item}>
          <Link className={getItemClass(item)} to={`/${item}`}>
            {capitalizeFirstLetter(item)}
          </Link>
          {item === "galerie" && (
            <div className={styles.galeryDropdown}>
              {categories.map((category) => (
                <Link
                  key={category.dbCategory}
                  to={`/galerie/${category.slug}`}
                >
                  {category.pageTitle}
                </Link>
              ))}
            </div>
          )}
        </li>
      ))}
      {/* {!user.isLoggedIn && (
        <Link to={"/login"} className={styles.logBtn}>
          Se connecter
        </Link>
      )} */}
      {user.isLoggedIn && (
        <button className={styles.logBtn} onClick={logout}>
          Déconnexion
        </button>
      )}
    </ul>
  );
};

export default Navbar;
