import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
    <div>Oups ! cette page n'existe pas</div>
    <Link to="/">Page d'accueil</Link>
    </>
  )
}

export default NotFound