import { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "./../api/apiHandler";
import UserContext from "../components/Auth/UserContext";
import categories from "./../data/categories";
import emptyProduct from "../data/emptyProduct";
//Components
import Title from "./../components/Title";
import EditDeleteIcons from "../components/EditDeleteIcons";
import AddEditModal from "./../components/AddEditModal";
import ConfirmDeleteModal from "./../components/ConfirmDeleteModal";
import ProductModal from "./../components/ProductModal";
//Styles
import styles from "./Gallery.module.scss";

const Gallery = () => {
  const [products, setProducts] = useState([]);
  const [adminModal, setAdminModal] = useState(null);
  const [productViewer, setProductViewer] = useState(null);
  const [filters, setFilters] = useState([]);

  const params = useParams();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const currentCategory = categories.find(
    (cat) => cat.slug === params.category
  );

  const getproducts = useCallback(async () => {
    let productsData = [];
    if (!currentCategory) {
      return navigate("/not-found");
    } else if (currentCategory.dbCategory === "ALL") {
      productsData = await api.getAllProducts();
      setProducts(productsData);
    } else {
      productsData = await api.getProductsByCategory(
        currentCategory.dbCategory
      );
    }
    setProducts(productsData);
  }, [navigate, currentCategory]);

  const getItemTypeFilters = (products) => {
    let itemTypes = products.map((p) => p.item_type);
    itemTypes = [...new Set(itemTypes)];
    return itemTypes;
  };

  const handleTypeBoxes = (e) => {
    if (e.target.checked) {
      setFilters([...filters, e.target.id]);
    } else {
      const updatedFilters = filters.filter((item) => item !== e.target.id);
      setFilters(updatedFilters);
    }
  };
  // const handleStatusFilter = (e) => {
  //   if (e.target.checked) {
  //     setFilters({ ...filters, status: ["AVAILABLE"] });
  //   } else {
  //     setFilters({ ...filters, status: ["AVAILABLE", "UNAVAILABLE"] });
  //   }
  // };

  useEffect(() => {
    getproducts();
  }, [getproducts]);

  const filteredProducts = useMemo(() => {
    const getFilteredProducts = () => {
      const filteredProducts =
        filters.length > 0
          ? products.filter((p) => filters.includes(p.item_type))
          : products;
      return filteredProducts;
    };
    return getFilteredProducts();
  }, [products, filters]);

  // console.log("filters", filters);
  // console.log('products', products);
  // console.log('filteredProducts', filteredProducts)

  if (!currentCategory || !products || !filteredProducts) return;

  return (
    <>
      <Title title={currentCategory.pageTitle}>
        {filteredProducts.length}{" "}
        {filteredProducts.length > 1 ? "porcelaines" : "porcelaine"}
        {user.isAdmin && (
          <button
            style={{ marginLeft: "8px" }}
            className="btn-black"
            onClick={() =>
              setAdminModal({ type: "add", product: emptyProduct })
            }
          >
            Ajouter
          </button>
        )}
      </Title>

      {/* FILTERS */}
      {getItemTypeFilters(products).length > 1 && (
        <div className={styles.filtersContainer}>
          {getItemTypeFilters(products).map((type) => (
            <div key={type} className={styles.checkAndLabel}>
              <input type="checkbox" id={type} onChange={handleTypeBoxes} />
              <label htmlFor={type}>{type}</label>
            </div>
          ))}
        </div>
      )}
      {/* <label htmlFor="availability">
        Uniquement les porcelaines disponibles
      </label>
      <input type="checkbox" id="availability" onChange={handleStatusFilter} /> */}

      {/* GALLERY */}
      <div className={styles.galleryContainer}>
        {filteredProducts.map((product, index) => (
          <div
            className={styles.productWrapper}
            key={product._id}
            onClick={() => setProductViewer(index)}
          >
            {user.isAdmin && (
              <EditDeleteIcons
                onEdit={(e) => {
                  e.stopPropagation();
                  setAdminModal({ type: "edit", product });
                }}
                onDelete={(e) => {
                  e.stopPropagation();
                  setAdminModal({ type: "delete", product });
                }}
              />
            )}
            <img
              src={product.images[0]}
              alt={`Les porcelaines de Marie-Paule - ${
                categories.find((cat) => cat.dbCategory === product.category)
                  .pageTitle
              } - ${product.name} - porcelaine peinte à la main`}
              loading="lazy"
            />
          </div>
        ))}
      </div>
      {productViewer !== null && (
        <ProductModal
          products={filteredProducts}
          index={productViewer}
          setProductViewer={setProductViewer}
          setAdminModal={setAdminModal}
        />
      )}
      {adminModal &&
        (adminModal.type === "add" || adminModal.type === "edit") && (
          <AddEditModal
            type={adminModal.type}
            setAdminModal={setAdminModal}
            product={adminModal.product}
            getproducts={getproducts}
          />
        )}
      {adminModal && adminModal.type === "delete" && (
        <ConfirmDeleteModal
          product={adminModal.product}
          setAdminModal={setAdminModal}
          getproducts={getproducts}
        />
      )}
    </>
  );
};

export default Gallery;
