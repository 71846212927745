import styled from "styled-components";
import edit from "./../images/icons/edit.svg";
import trash from "./../images/icons/trash.svg";

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  background-color: #D9D4CF;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 8px;
  position: absolute;
  right: 0;
  top: 0;
`;
const Icon = styled.img`
  height: 30px;
  cursor: pointer;
`;

const EditDeleteIcons = ({ onEdit, onDelete }) => {
  return (
    <IconsWrapper>
      <Icon src={edit} alt="modify" onClick={onEdit} />
      <Icon src={trash} alt="delete" onClick={onDelete} />
    </IconsWrapper>
  );
};

export default EditDeleteIcons;
