import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

const api = {
  //----------PRODUCTS
  async getAllProducts() {
    try {
      const res = await apiClient.get("/products");
      return res.data;
    } catch (e) {
      console.error(e);
      //   return "error";
    }
  },
  async getProductsByCategory(category) {
    try {
      const res = await apiClient.get(`/products/category/${category}`);
      return res.data;
    } catch (e) {
      console.error(e);
    }
  },
  async getOneProduct(product_id) {
    try {
      const res = await apiClient.get(`/products/details/${product_id}`);
      return res.data;
    } catch (e) {
      console.error(e);
    }
  },
  //-----ADMIN CRUD
  async createProduct(productInfo) {
    try {
      const res = await apiClient.post("/products/new", productInfo);
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  },
  async editProduct(productInfo, productId) {
    try {
      const res = await apiClient.patch(`/products/${productId}`, productInfo);
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  },
  async deleteProduct(productId) {
    try {
      const res = await apiClient.delete(`/products/${productId}`);
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  },

  //----------AUTH
  async login(userInfo) {
    try {
      const res = await apiClient.post("/auth/login", userInfo);
      // console.log('res', res)
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  },

  async logout() {
    try {
      const res = await apiClient.delete("/auth/logout");
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  },

  async getCurrentUser() {
    try {
      const res = await apiClient.get("/auth/current-user");
      return res.data;
    } catch (e) {
      return e.response.data;
    }
  },
};

export default api;
