import { useState } from "react";
import ModalContainer from "./ModalContainer";
import ImagePreview from "./ImagePreview";
import styles from "./AddEditImages.module.scss";

const AddEditImages = (props) => {
  const { images, handleConfirmFiles, setShowImageModal } = props;
  const [tempImages, setTempImages] = useState({ ...images });

  const deleteUploadedImage = (imgUrl) => {
    const updatedUploadedImages = tempImages.uploaded.filter(
      (i) => i !== imgUrl
    );
    const updatedImagesToDestroy = [...tempImages.toDestroy, imgUrl];
    setTempImages({
      ...tempImages,
      uploaded: updatedUploadedImages,
      toDestroy: updatedImagesToDestroy,
    });
  };

  const addImageToUpload = (e) => {
    console.log('e.target.files :>> ', e.target.files);
    if (!e.target.files[0]) return;
    const updatedImagesToUpload = [...tempImages.toUpload, ...e.target.files];
    setTempImages({ ...tempImages, toUpload: updatedImagesToUpload });
  };

  const deleteImageToUpload = (file) => {
    const updatedImagesToUpload = tempImages.toUpload.filter(
      (i) => i.name !== file.name && i.lastModified !== file.lastModified
    );
    setTempImages({ ...tempImages, toUpload: updatedImagesToUpload });
  };

  return (
    <ModalContainer>
      <div>Images actuelles:</div>
      <div className={styles.imageListContainer}>
        {tempImages.uploaded.map((imageUrl) => (
          <div className={styles.previewContainer} key={imageUrl}>
            <ImagePreview image={imageUrl} />
            <p onClick={() => deleteUploadedImage(imageUrl)}>Supprimer</p>
          </div>
        ))}
      </div>
      <div>Images à charger:</div>
      {/* TODO style the input :-)  */}
      <input
        type="file"
        id="images"
        name="images"
        multiple
        onChange={addImageToUpload}
      />
      <div className={styles.imageListContainer}>
        {tempImages.toUpload.map((imageFile) => (
          <div
            className={styles.previewContainer}
            key={imageFile.name + imageFile.lastModified}
          >
            <ImagePreview image={imageFile} />
            <p onClick={() => deleteImageToUpload(imageFile)}>Supprimer</p>
          </div>
        ))}
      </div>
      <div className="buttons-wrapper">
        <button
          className="btn-black"
          onClick={() => handleConfirmFiles(tempImages)}
        >
          confirmer
        </button>
        <button className="btn-black" onClick={() => setShowImageModal(false)}>
          annuler
        </button>
      </div>
    </ModalContainer>
  );
};

export default AddEditImages;
