const emptyProduct = {
  name: "",
  category: "",
  item_type: "",
  images: [],
  description_main: "",
  description_technical: "Peint à la main.",
  height: "",
  width: "",
  length: "",
  diameter: "",
  status: "",
  price: "",
};
export default emptyProduct;
