import styled from "styled-components";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(60, 60, 60);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  ${(props) => props.cover && "height: calc(100vh - 80px)"};
  ${(props) => props.cover && "width: calc(100vw - 80px)"};
  background-color: beige;
  border-radius: 8px;
  padding: 24px;
  position: relative;
  overflow: auto;
`;

const ModalContainer = ({ children, cover }) => {
  return (
    <Backdrop>
      <Container cover={cover}>{children}</Container>
    </Backdrop>
  );
};

export default ModalContainer;
