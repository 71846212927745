import decoration from "./../images/categories/vaisselle-decoration.png";
import jewelry from "./../images/categories/pendentifs.png";
import frames from "./../images/categories/tableaux.png";
import allProductsImg from "./../images/categories/toutes-les-porcelaines.png";
import allProductsImgMobile from "./../images/categories/toutes-les-porcelaines-m.png";

const categories = [
  {
    dbCategory: "TABLEWARE_DECORATION",
    slug: "vaisselle-decoration",
    pageTitle: "Vaisselle & Décoration",
    img: decoration,
  },
  {
    dbCategory: "JEWELRY",
    slug: "pendentifs",
    pageTitle: "Pendentifs",
    img: jewelry,
  },
  {
    dbCategory: "FRAMES",
    slug: "tableaux",
    pageTitle: "Tableaux",
    img: frames,
  },
  {
    dbCategory: "ALL",
    slug: "toutes-les-porcelaines",
    pageTitle: "Toutes les porcelaines",
    img: allProductsImg,
    imgMobile: allProductsImgMobile,
  },
];

export default categories;
