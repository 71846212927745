import { useState, useContext } from "react";
import UserContext from "./Auth/UserContext";
import ModalContainer from "./ModalContainer";
import EditDeleteIcons from "./EditDeleteIcons";
import closeIcon from "./../images/icons/cross.svg";
import chevronLeft from "./../images/icons/chevron-left-solid.svg";
import chevronRight from "./../images/icons/chevron-right-solid.svg";
import categories from "../data/categories";
import styles from "./ProductModal.module.scss";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

const ProductModal = (props) => {
  const { products, index, setProductViewer, setAdminModal } = props;
  const [currentIndex, setCurrentIndex] = useState(index);

  const user = useContext(UserContext);

  //   console.log("products", products);
  //   console.log("index", currentIndex);

  const navigateIndex = (direction) => {
    let updatedIndex;
    if (direction === "next") {
      updatedIndex = currentIndex + 1;
    } else {
      updatedIndex = currentIndex - 1;
    }
    setCurrentIndex(updatedIndex);
  };
  return (
    <ModalContainer cover>
      {currentIndex > 0 && (
        <img
          className={styles.icon}
          src={chevronLeft}
          alt="porcelaine précédente"
          onClick={() => navigateIndex("previous")}
        />
      )}
      {currentIndex < products.length - 1 && (
        <img
          className={styles.icon}
          src={chevronRight}
          alt="porcelaine suivante"
          onClick={() => navigateIndex("next")}
        />
      )}
      <img
        className={`${styles.icon} + ${styles.closeIcon}`}
        src={closeIcon}
        alt="fermer"
        onClick={() => setProductViewer(null)}
      />
      <div className={styles.productWrapper}>
        <div className={styles.bloc1}>
          <ImageGallery
            // additionalClass={adminModal ? styles.hideIcons : ""}
            showPlayButton={false}
            onErrorImageURL={process.env.REACT_APP_DEFAULT_PRODUCT_PICTURE}
            items={products[currentIndex].images.map((img) => ({
              original: img,
              thumbnail: img,
              thumbnailClass: styles.thumbnailItem,
              originalAlt: `mpf porcelaines - ${
                categories.find(
                  (cat) => cat.dbCategory === products[currentIndex].category
                ).pageTitle
              } - ${products[currentIndex].name} - porcelaine peinte à la main`,
              thumbnailAlt: `mpf porcelaines - ${
                categories.find(
                  (cat) => cat.dbCategory === products[currentIndex].category
                ).pageTitle
              } - ${products[currentIndex].name} - porcelaine peinte à la main`,
            }))}
          />
        </div>
        <div className={styles.bloc2}>
          {user.isAdmin && (
            <EditDeleteIcons
              onEdit={() => setAdminModal({ type: "edit", product: products[currentIndex] })}
              onDelete={() => setAdminModal({ type: "delete", product: products[currentIndex] })}
            />
          )}
          <h1>{products[currentIndex].name}</h1>
          <p>{products[currentIndex].description_main}</p>
          <p>{products[currentIndex].description_technical}</p>
          {/* <div>Dimensions: </div> */}
          {products[currentIndex].height > 0 && (
            <div>Hauteur : {products[currentIndex].height} cm</div>
          )}
          {products[currentIndex].length > 0 && (
            <div>Longueur : {products[currentIndex].length} cm</div>
          )}
          {products[currentIndex].width > 0 && (
            <div>Largeur : {products[currentIndex].width} cm</div>
          )}
          {products[currentIndex].diameter > 0 && (
            <div>Diamètre : {products[currentIndex].diameter} cm</div>
          )}
          {products[currentIndex].status === "AVAILABLE" &&
            products[currentIndex].price > 0 && (
              <p>Prix : {products[currentIndex].price}€</p>
            )}
          {products[currentIndex].status === "UNAVAILABLE" && (
            <p>
              <i>Non disponible</i>
            </p>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default ProductModal;
