import styled from "styled-components";

const PreviewImg = styled.img`
  max-width: 100px;
`;

const ImagePreview = ({ image }) => {
  return (
    <>
      {typeof image === "object" && ( //image to upload == File object
        <PreviewImg
          src={URL.createObjectURL(image)}
          alt={image.name}
        />
      )}
      {typeof image === "string" && ( //uploaded image == url string
        <PreviewImg src={image} alt={image} />
      )}
    </>
  );
};

export default ImagePreview;
