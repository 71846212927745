import Title from "../components/Title";
import ContactForm from "./../components/ContactForm";
import styled from "styled-components";

const Paragraph = styled.p`
  text-align: justify;
  padding: 1em 1em 0;
  margin: 0 auto;
  width: fit-content;
`;

const Contact = () => {
  return (
    <>
      <Title title="Me contacter"></Title>
      <Paragraph>
        Pour tout renseignement ou toute demande, vous pouvez me contacter par
        email :{" "}
        <a href="mailto:contact@mpf-porcelaines.com">
          contact@mpf-porcelaines.com
        </a>
      </Paragraph>
      <Paragraph>ou vous pouvez utiliser le formulaire ci-dessous.</Paragraph>
      <ContactForm templateId={"contact_form_mpf"} />
    </>
  );
};

export default Contact;
