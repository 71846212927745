import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../components/Auth/UserContext";
import Title from "./../components/Title";
import api from "./../api/apiHandler";
import styles from "./Login.module.scss";

const Login = () => {
  const [userInfo, setUserInfo] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("")

  const user = useContext(UserContext)
  const navigate = useNavigate();

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [key]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.login(userInfo);
      // console.log(response);
      if (response.role) {
        user.login(response)
        return navigate("/galerie");
      } else {
        setErrorMessage(response.message)
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Title title="Connexion" />

      <form
        className={styles.loginForm}
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" />
        <label htmlFor="password">Mot de passe</label>
        <input type="password" id="password" name="password" />
        <button className="btn-black">Valider</button>
      </form>
    {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

export default Login;
