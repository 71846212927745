import { Link } from "react-router-dom";
import styled from "styled-components";
import categories from "../data/categories";
import Title from "./../components/Title";
import CategoryCard from "../components/CategoryCard";

const CategoriesWrapper = styled.div`
  margin: 0 4vw 6vh;
  background-color: #b0b9a8;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  > * {
    flex: 30%;
    max-width: 30%;
  }
  > * {
    &:last-child {
      flex: 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    > * {
      flex: 100%;
      max-width: 100%;
    }
  }
`;

const GalleryMain = () => {
  return (
    <>
      <Title title={"Galerie"}></Title>
      <CategoriesWrapper>
        {categories.map((cat) => (
          <Link key={cat.dbCategory} to={`/galerie/${cat.slug}`}>
            <CategoryCard category={cat} />
          </Link>
        ))}
      </CategoriesWrapper>
    </>
  );
};

export default GalleryMain;
