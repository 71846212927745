import { useState } from "react";
import api from "./../api/apiHandler";
import Title from "./Title";
import ModalContainer from "./ModalContainer";
import AddEditImages from "./AddEditImages";
import ImagePreview from "./ImagePreview";
import Spinner from "./LoadingSpinner";
import styles from "./AddEditModal.module.scss";

const AddEditModal = ({ type, product, setAdminModal, getproducts }) => {
  const [newProduct, setNewProduct] = useState({
    ...product,
    images: {
      uploaded: product.images,
      toUpload: [],
      toDestroy: [],
    },
  });
  const [showImageModal, setShowImageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveProduct = async (productData) => {
    //TODO handle errors
    if (type === "edit") {
      await api.editProduct(productData, product._id);
    } else {
      await api.createProduct(productData);
    }
  };

  const handleChange = (e) => {
    //handles all inputs except files -> handleConfirmFiles
    const key = e.target.name;
    const value = e.target.value;
    // auto-fill item_type for jewels and paintings
    if (key === "category" && value === "JEWELRY") {
      setNewProduct({ ...newProduct, [key]: value, item_type: "Pendentifs" });
    } else if (key === "category" && value === "FRAMES") {
      setNewProduct({ ...newProduct, [key]: value, item_type: "Tableaux" });
    } else setNewProduct({ ...newProduct, [key]: value });
  };
  const handleConfirmFiles = (tempImg) => {
    setShowImageModal(false);
    setNewProduct({ ...newProduct, images: { ...tempImg } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // prepare data
    const fd = new FormData();
    for (const key in newProduct) {
      if (newProduct[key]) {
        if (["_id", "createdAt", "images", "updatedAt"].includes(key)) continue;
        fd.append(key, newProduct[key]);
      }
    }
    for (const key in newProduct.images) {
      newProduct.images[key].forEach((image) =>
        fd.append(`images_${key}[]`, image)
      );
    }

    // for (const pair of fd.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }

    // send data to backend
    await saveProduct(fd);
    //refresh
    getproducts();
    setIsLoading(false);
    // close modal
    setAdminModal(null);
  };

  console.log(newProduct);

  return (
    <ModalContainer>
      <Title>
        {type === "add" ? "Ajouter un produit" : "Modifier le produit"}
      </Title>
      <form className={styles.addEditForm}>
        <div className={styles.contentWrapper}>
          <div className={styles.bloc1}>
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              name="name"
              value={newProduct.name}
              onChange={handleChange}
            />
            <label htmlFor="category">Catégorie</label>
            <select
              id="category"
              name="category"
              value={newProduct.category}
              onChange={handleChange}
            >
              <option value="">--Sélectionner--</option>
              <option value="TABLEWARE_DECORATION">
                Vaisselle & Décoration
              </option>
              <option value="JEWELRY">Pendentifs</option>
              <option value="FRAMES">Tableaux</option>
            </select>
            <label htmlFor="item_type">Type de produit</label>
            <input
              type="text"
              id="item_type"
              name="item_type"
              value={newProduct.item_type}
              onChange={handleChange}
            />
            <label htmlFor="description_main">Description</label>
            <textarea
              id="description_main"
              name="description_main"
              rows="7"
              value={newProduct.description_main}
              onChange={handleChange}
            />
            <label htmlFor="description_technical">
              Complément, info technique
            </label>
            <textarea
              id="description_technical"
              name="description_technical"
              rows="3"
              value={newProduct.description_technical}
              onChange={handleChange}
            />
          </div>
          <div className={styles.bloc2}>
            <div className={styles.dimensionsWrapper}>
              <div>
                <label htmlFor="height">Hauteur</label>
                <input
                  type="number"
                  id="height"
                  name="height"
                  value={newProduct.height}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="width">Largeur</label>
                <input
                  type="number"
                  id="width"
                  name="width"
                  value={newProduct.width}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="length">Longueur</label>
                <input
                  type="number"
                  id="length"
                  name="length"
                  value={newProduct.length}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="diameter">Diamètre</label>
                <input
                  type="number"
                  id="diameter"
                  name="diameter"
                  value={newProduct.diameter}
                  onChange={handleChange}
                />
              </div>
            </div>
            <label htmlFor="status">Statut</label>
            <select
              id="status"
              name="status"
              value={newProduct.status}
              onChange={handleChange}
            >
              <option value="">--Sélectionner--</option>
              <option value="AVAILABLE">Disponible</option>
              <option value="UNAVAILABLE">Indisponible</option>
            </select>
            <label htmlFor="price">Prix</label>
            <input
              type="number"
              id="price"
              name="price"
              value={newProduct.price}
              onChange={handleChange}
            />
            <button type="button" onClick={() => setShowImageModal(true)}>
              Ajouter/Modifier les images
            </button>
            <div className={styles.previewList}>
              {newProduct.images.uploaded?.map((imgUrl) => (
                <ImagePreview image={imgUrl} key={imgUrl} />
              ))}
              {newProduct.images.toUpload?.map((imgFile) => (
                <ImagePreview image={imgFile} key={imgFile.name} />
              ))}
            </div>
            {showImageModal && (
              <AddEditImages
                images={newProduct.images}
                handleConfirmFiles={handleConfirmFiles}
                setShowImageModal={setShowImageModal}
              />
            )}
          </div>
        </div>
        <div className="buttons-wrapper">
          <button className="btn-black" onClick={handleSubmit}>
            {isLoading ? <Spinner /> : "Valider"}
          </button>
          <button
            type="button"
            className="btn-black"
            onClick={() => setAdminModal(null)}
          >
            Annuler
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default AddEditModal;
