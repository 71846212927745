import { BrowserRouter, Routes, Route } from "react-router-dom";
// Components
import Navbar from "./components/Navbar";
//Pages
import Home from "./pages/Home";
import GalleryMain from "./pages/GalleryMain";
import Gallery from "./pages/Gallery";
import ProductDetails from "./pages/ProductDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";

import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          {/* {["/", "/accueil"].map((path) => (
            <Route path={path} element={<Home />} />
          ))} */}
          <Route path="/" element={<Home />} />
          <Route path="/accueil" element={<Home />} />
          <Route path="/galerie" element={<GalleryMain />} />
          <Route path="/galerie/:category" element={<Gallery />} />
          <Route path="/galerie/details/:id" element={<ProductDetails />} />
          <Route path="/infos" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />

          {/*
          <Route path="/galerie/:id" element={ProductPage} />
          <Route path="/signup" element={Signup} />
          <ProtectedRoute path="/profile" element={Profile} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
