import { Link } from "react-router-dom";
import Title from "./../components/Title";
import styles from "./Home.module.scss";

const Home = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <Title title={"Les Porcelaines de Marie-Paule"}>
          porcelaine peinte à la main
        </Title>
        <p>
          Peintre sur porcelaine depuis 2004, je vous souhaite la bienvenue sur
          mon site. Ma passion pour cet art s'est forgée au fil des années
          auprès de Thérèse BOLLIET pour la technique classique et auprès
          d'autres artistes pour les techniques modernes. Je me suis formée à
          l'encadrement auprès de Martine BOCQUET depuis 2009. Cet art me permet
          de mettre en valeur, notamment, mes plaques en porcelaine peintes à la
          main.
        </p>
        <Link to="/galerie/vaisselle-decoration">
          <div className={styles.categoryWrapper}>
            <div>
              <h3>Les pièces de vaisselle et de décoration</h3>
              <p>
                Les objets en porcelaine que je peins peuvent être uniquement
                décoratifs mais la plupart sont d'usage courant : vaisselle (la
                porcelaine peinte passe en machine), vases, ou encore
                vide-poches.
              </p>
            </div>
            <img
              src="/categories/vaisselle-decoration.png"
              alt="Les porcelaines de Marie-Paule - catégorie vaisselle et décoration"
            />
          </div>
        </Link>

        <Link to="/galerie/pendentifs">
          <div className={styles.categoryWrapper}>
            <div>
              <h3>Les pendentifs</h3>
              <p>
                Les pendentifs sont entièrement en porcelaine, avec ou sans
                ajout de strass, et peuvent se porter avec une chaîne, un cordon
                réglable ou un tour de cou métallique.
              </p>
            </div>
            <img
              src="/categories/pendentifs.png"
              alt="Les porcelaines de Marie-Paule - catégorie pendentifs"
            />
          </div>
        </Link>

        <Link to="/galerie/tableaux">
          <div className={styles.categoryWrapper}>
            <div>
              <h3>Les tableaux</h3>
              <p>
                Les tableaux sont des plaques de porcelaine peintes à la main
                puis encadrées : à l'aide de différentes matières, couleurs,
                épaisseurs et jeux de perspectives, l'objet central est prolongé
                et devient une nouvelle oeuvre.
              </p>
            </div>
            <img
              src="/categories/tableaux.png"
              alt="Les porcelaines de Marie-Paule - catégorie tableaux"
            />
          </div>
        </Link>
      </div>
    </>
  );
};

export default Home;
