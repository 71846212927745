import styled from "styled-components";

const MainTitle = styled.h1`
  font-size: 2.2em;
  margin-top: 0.5em;
  line-height: 1.3em;
`;

const Subtitle = styled.h2`
  font-size: 1.8em;
  font-weight: 400;
  margin-top: 0;
`;

const Title = ({ title, children }) => {
  return (
    <>
      <MainTitle>{title}</MainTitle>
      {children && <Subtitle>{children}</Subtitle>}
    </>
  );
};

export default Title;
